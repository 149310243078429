'use client'

import { getCookie } from 'cookies-next'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import ChatLayout from '@/components/Admin/Conversations/ChatLayout'
import Matcher from '@/components/Admin/Matcher'
import Chatbot, { IndexPageContext } from '@/components/Chatbot'
import IndexHeader from '@/components/IndexHeader'

import { switchLanguage } from '@/i18n'
import { cn } from '@/utils/clsx'

const Home = () => {
  const { error, isLoading, user } = useAuth()
  const router = useRouter()
  const pathname = usePathname()
  const { settings } = useDbSettings()
  const { isMatcherOpen } = useGrantApplicationState()

  useEffect(() => {
    if (error) {
      router.replace(
        `/login${pathname ? '?redirect=' + encodeURIComponent(pathname) : ''}`
      )
    }
    if (
      settings?.requireSubscription &&
      user &&
      !user.subscribed &&
      user.role !== 'ADMIN'
    ) {
      router.replace('/landing')
    }
  }, [error, user, settings])

  // Set language from cookie
  const lang = getCookie('locale')
  if (lang) {
    setTimeout(() => switchLanguage(lang))
  }

  return (
    <>
      {isLoading || error ? (
        <></>
      ) : (
        <ChatLayout>
          {isMatcherOpen ? (
            <IndexPageContext.Provider value={true}>
              <main
                className={cn(
                  'relative h-screen flex flex-col bg-background dark:bg-dark-background shadow-md rounded'
                )}
              >
                <div className='w-full'>
                  <IndexHeader />
                </div>
                <Matcher />
              </main>
            </IndexPageContext.Provider>
          ) : (
            <Chatbot indexPage useAdminAgent />
          )}
        </ChatLayout>
      )}
    </>
  )
}

export default Home
